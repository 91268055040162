











import Vue from 'vue';
import Component from 'vue-class-component';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { DataApplication } from '@/models/data/models';

@Component({
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      DataApplication,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class DataApplicationForm extends Vue {
  beforeSaveHook(application: DataApplication) {
    if (this.$props.id === '0') {
      application.organisation = this.$store.getters['global/organisation'].id;
    }
  }
}
